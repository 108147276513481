<template>
  <div id="menus-list-main">
    <NForm inline label-placement="left">
      <NFormItem label="搜索关键词：">
        <NInput
          v-model:value="reqParams.menu_name"
          @update:value="ChangeTitle"
        />
      </NFormItem>
      <NFormItem label="状态：">
        <div style="width: 200px">
          <NSelect
            :options="statusOptions"
            v-model:value="reqParams.status"
          ></NSelect>
        </div>
      </NFormItem>
      <NFormItem>
        <NSpace>
          <NButton type="primary" @click="MenuSearch">搜索</NButton>
          <NButton @click="handleClear">清除</NButton>
        </NSpace>
      </NFormItem>
    </NForm>

    <div style="margin-bottom: 20px; text-align: right">
      <NButton
        type="primary"
        class="menus-list-add"
        @click="sendMail('', 2)"
        v-if="ownUrlPermission('system-power/menu-create')"
        >新增</NButton
      >
    </div>
    <NDataTable
      :columns="tableColumns"
      :data="tableData"
      :row-key="(row) => row.id"
    />

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
import { ref, h } from "vue";
import {
  NSpace,
  NDataTable,
  NButton,
  NForm,
  NFormItem,
  NInput,
  NSelect,
  NImage,
  useMessage,
  useDialog
} from "naive-ui";
import { resStatusEnum } from "@/enumerators/http.js";
import {
  getMenus,
  PostMenuDelete,
  PostMenuUpdateStatus,
} from "@/api/admin-menus.js"; // 列表 删除 编辑详情 编辑 更改状态
import accountType from "@/enumerators/account-admin";
import { toRaw } from "@vue/reactivity";
import PageLoading from "@/components/PageLoading/index.vue";
import { useRouter } from "vue-router";
import ownUrlPermission from "@/utils/own-url-permission.js";

const { SUCCESS, ERROR } = resStatusEnum;
const message = useMessage();
const loading = ref(false);
const router = useRouter();
const dialog = useDialog();

const tableColumns = [
  { title: "菜单名称", key: "menu_name" },
  { title: "URL", key: "menu_url" },
  {
    title: "图标",
    key: "file_url",
    render(row) {
      return h(NImage, {
        src: row.file_url,
        style: { width: "40px", height: "40px" },
      });
    },
  },
  {
    title: "状态",
    key: "status",
    render: (row) =>
      h("span", null, { default: () => getResourceName(row.status) }),
  },
  {
    title: "等级",
    key: "level",
    render: (row) =>
      h("span", null, { default: () => getResourceName1(row.level) }),
  },
  { title: "排序", key: "iorder" },
  {
    title: "备注",
    key: "remark",
    render(row) {
      return h({
        style: {
          width: "40px",
          height: "40px",
          "text-overflow": "ellipsis",
          "text-overflow": "ellipsis",
          "*white-space": "nowrap",
          overflow: "hidden",
        },
      });
    },
  },
  {
    title: "操作",
    render: (row) =>
      h(
        NSpace,
        null,
        {
          default: () => {
            const tempArr = [];
            // 编辑
            if (ownUrlPermission("system-power/menu-update")) {
              tempArr.push(
                h(
                  NButton,
                  {
                    text: true,
                    type: "primary",
                    onClick: () => sendMail(row, 1),
                  },
                  { default: () => "编辑" }
                )
              );
              tempArr.push(
                h(
                  NButton,
                  {
                    text: true,
                    type: "primary",
                    onClick: () => sendMail(row, 0),
                  },
                  { default: () => "添加子菜单" }
                )
              );
            }
            // 状态改变
            if (ownUrlPermission("system-power/menu-update-status")) {
              tempArr.push(
                h(
                  NButton,
                  { text: true, type: "primary", onClick: () => showHide(row) },
                  {
                    default: () => {
                      if (row.status == "1") {
                        if (row.is_delete == "1") {
                          return "隐藏";
                        }
                      } else {
                        return "显示";
                      }
                    },
                  }
                )
              );
            }
            // 删除
            if (ownUrlPermission("system-power/menu-delete")) {
              tempArr.push(
                h(
                  NButton,
                  { text: true, type: "error", onClick: () => DeleteMenu(row) },
                  {
                    default: () => {
                      if (row.is_delete == "1") {
                        return "删除";
                      } else {
                        return false;
                      }
                    },
                  }
                )
              );
            }
            return tempArr;
          },
        }
        // [
        //   h(NButton, { text: true, type: 'primary',onClick: () => sendMail(row,1)}, { default: () => '编辑' }),
        //   h(NButton, { text: true, type: 'primary',onClick: () => sendMail(row,0) }, { default: () => '添加子菜单' }),

        //   h(NButton, { text: true, type: 'primary',onClick: () => showHide(row) }, { default: () =>{
        //     if(row.status=='1'){
        //       if(row.is_delete =='1'){
        //         return '隐藏'
        //       }
        //     }else{
        //      return '显示'
        //     }
        //   }  }),
        //   h(NButton, { text: true, type: 'error',onClick: () => DeleteMenu(row) }, { default: () =>{
        //     if(row.is_delete =='1'){
        //      return '删除'
        //     }else{
        //      return false
        //     }
        //   }}),
        // ]
      ),
  },
];

const statusOptions = [
  {
    label: "显示",
    value: 1,
  },
  {
    label: "隐藏",
    value: 0,
  },
];

// 常用的变量
const reqParams = ref({
  menu_name: null,
  status: null,
});

// 监控标题输入
function ChangeTitle(title) {
  pagination.page = 1;
  formValue.page = 1;
}

// 清除时的赋值
const clearValue = () => ({
  menu_name: null,
  status: null,
});

const tableData = ref([]);

// 数据初始化
function onMounted(params) {
  loading.value = true;
  getMenus(params)
    .then((res) => {
      if (res.code === SUCCESS) {
        loading.value = false;
        tableData.value = res.data || [];
      }
    })
    .catch((err) => {});
}
onMounted();

// 菜单搜索
function MenuSearch() {
  var SearchParameters = toRaw(reqParams);
  let params = {};
  params.menu_name = SearchParameters.value.menu_name; // menu_name
  params.status = SearchParameters.value.status; // status
  onMounted(params);
}

// 菜单显隐操作
function showHide(row) {
  loading.value = true;
  var showHideData = {};
  showHideData.id = row.id;
  if (row.status == "1") {
    // 隐藏
    showHideData.status = 0;
  } else {
    // 显示
    showHideData.status = 1;
  }
  PostMenuUpdateStatus(showHideData)
    .then((res) => {
      if (res.code === SUCCESS) {
        loading.value = false;
        // 请求完成后 刷新数据
        message.info(res.msg);
        // MenuSearch()
        location.reload();
      }
    })
    .catch((err) => {});
}

// 清除时
function handleClear() {
  toRaw(reqParams).value.menu_name = null;
  toRaw(reqParams).value.status = null;
}

// 删除菜单
function DeleteMenu(row) {
  
  let params = { id: row.id };
  dialog.warning({
    title: "确认删除",
    content: `确认删除 菜单: ${row.menu_name}`,
    positiveText: "确定",
    negativeText: "取消",
    onPositiveClick: () => {
      loading.value = true;
      PostMenuDelete(params)
        .then((res) => {
          if (res.code === SUCCESS) {
            loading.value = false;
            message.info(res.msg);
            // 删除完成后 刷新数据
            // MenuSearch();
            location.reload();
          }
        })
        .catch((err) => {});
    },
    onNegativeClick: () => {},
  });
}

// 编辑或添加时
function sendMail(row, is) {
  // 编辑时1  添加子菜单0  新增2
  if (is == 0) {
    router.push({
      path: "/system-power/menus/edit",
      query: { id: row.id, is },
    });
  } else if (is == 1) {
    router.push({
      path: "/system-power/menus/edit",
      query: { id: row.id, is },
    });
  } else if (is == 2) {
    router.push({
      path: "/system-power/menus/edit",
      query: { id: "", is },
    });
  }
}

// 状态
function getResourceName(status) {
  return accountType[status] || "";
}

// 等级
function getResourceName1(status) {
  if (status == "1") {
    return "一级";
  } else if (status == "2") {
    return "二级";
  } else if (status == "3") {
    return "三级";
  } else if (status == "4") {
    return "四级";
  } else if (status == "5") {
    return "五级";
  } else if (status == "6") {
    return "六级";
  }
}
</script>

<style scoped>
#menus-list-main {
  box-sizing: border-box;
  padding: 50px 85px 50px 60px;
  background: #fff;
}
.menus-list-add {
  width: 120px;
}
a {
  text-decoration: none;
}
</style>
<style lang="less">
#menus-list-main .n-data-table-td .n-image img {
  width: 80px;
}
</style>
